<template>
  <div class="property-room-addons">
    <bg-card variant="line">
      <div class="property-room-addons__flex property-room-addons__header">
        <bg-text size="heading-4">Biaya & Layanan Tambahan</bg-text>
        <router-link
          v-if="accessEditAdditionalPrice"
          :to="formPropertyAdditionalPriceRoute">
          <bg-button>Ubah</bg-button>
        </router-link>
      </div>
      <bg-divider class="property-room-addons__divider" />
      <div v-if="additionalData">
        <bg-grid>
          <bg-grid-item
            v-for="(item,i) in feeList"
            :key="i"
            :col="3">
            <bg-text size="body-1">{{ item.name }}</bg-text>
            <div class="property-room-addons__item">
              <bg-text
                v-if="item.amount_type === 'percentage'"
                size="body-2">{{ item.amount }}%</bg-text>
              <bg-text
                v-else-if="item.amount_type === 'nominal'"
                size="body-2">
                {{ item.amount | rupiah }}
                <span v-if="item.duration_unit">/
                  <span v-if="item.duration > 1">{{ item.duration }} </span>
                  {{ item.duration_unit | label }}
                </span>
              </bg-text>
            </div>
          </bg-grid-item>
        </bg-grid>
        <div v-if="otherFeeList.length > 0">
          <bg-divider class="property-room-addons__divider" />
          <bg-grid>
            <bg-grid-item
              v-for="(item,i) in otherFeeList"
              :key="i"
              :col="3">
              <bg-text size="body-1">{{ item.name }}</bg-text>
              <div class="property-room-addons__item">
                <bg-text
                  v-if="item.amount_type === 'percentage'"
                  size="body-2">{{ item.amount }}%</bg-text>
                <bg-text
                  v-else-if="item.amount_type === 'nominal'"
                  size="body-2">
                  {{ item.amount | rupiah }}
                  <span v-if="item.duration_unit">/
                    <span v-if="item.duration > 1">{{ item.duration }} </span>
                    {{ item.duration_unit | label }}
                  </span>
                </bg-text>
              </div>
            </bg-grid-item>
          </bg-grid>
        </div>
      </div>
      <p v-else>-</p>
    </bg-card>
  </div>
</template>

<script>
import { BgCard, BgText, BgDivider, BgGrid, BgGridItem, BgButton } from 'bangul-vue';
import { rupiahFormatter } from 'Utils/formatter';

export default {
  name: 'PropertyRoomAddOnsCard',

  components: {
    BgCard,
    BgText,
    BgGrid,
    BgGridItem,
    BgDivider,
    BgButton
  },

  filters: {
    rupiah: rupiahFormatter,
    label(value) {
      switch (value) {
        case 'day':
          return 'Hari';
        case 'month':
          return 'Bulan';
        case 'week':
          return 'Minggu';
        case 'year':
          return 'Tahun';
        default:
          return '';
      }
    }
  },

  props: {
    listingData: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    accessEditAdditionalPrice() {
      return this.$store.getters.xCheckUserPermission('property-detail-edit-biaya-layanan');
    },
    propertyId() {
			return this.$route.params.propertyId;
		},
    additionalData() {
      return this.listingData?.additional_fees || [];
    },
    listingId() {
      return this.listingData?.id || 0;
    },
    otherFeeList() {
      return this.additionalData.filter(other => other.type === 'additional');
    },
    feeList() {
      return this.additionalData.filter(fee => fee.type !== 'additional');
    },
    formPropertyAdditionalPriceRoute() {
      return {
        name: 'edit.property-additional-fee',
        params: {
          propertyId: this.propertyId,
          listingId: this.listingId
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyRoomAddOnsCard.scss"></style>
