<template>
  <div class="property-data-carousel" :id="carouselId">
    <div v-if="imageList">
      <div class="property-data-carousel__top-section">
        <bg-text size="heading-5">Foto Kamar</bg-text>
        <div class="property-data-carousel__nav">
          <div
            :id="navigationPrev"
            class="property-data-carousel__swiper-nav--prev">
            <bg-button-icon
              icon="chevron-left"
              size="sm"
              class="property-data-carousel__swiper-nav-icon"
            />
          </div>
          <div
            :id="navigationNext"
            class="property-data-carousel__swiper-nav--next">
            <bg-button-icon
              icon="chevron-right"
              size="sm"
              class="property-data-carousel__swiper-nav-icon"
            />
          </div>
        </div>
      </div>
    </div>
    <p v-else>-</p>
    <bg-swiper
      :swiper-options="swiperOptions"
      :show-navigation="false">
      <bg-swiper-slide v-for="(image,title) in imageList" :key="title">
        <property-data-slide-item
          :title="title"
          :image="image[0]"
          @handle-click-photo="handleShowModal(image,title)"
        />
        </bg-swiper-slide>
    </bg-swiper>
    <property-photo-modal
      :is-show-photo="isShowModal"
      :slides="imageSlide"
      :title="slideTitle"
      @handle-close-modal="handleShowModal"/>
  </div>
</template>

<script>
import { BgSwiper, BgSwiperSlide, BgButtonIcon, BgText } from 'bangul-vue';
import PropertyDataSlideItem from '@admin/pages/PropertyDetailKos/components/PropertyDataSlideItem';
import PropertyPhotoModal from '@admin/pages/PropertyDetailKos/components/PropertyPhotoModal';

export default {
  name: 'PropertyDataCarousel',

  components: {
    BgSwiper,
    BgSwiperSlide,
    BgButtonIcon,
    BgText,
    PropertyPhotoModal,
    PropertyDataSlideItem
  },

  data() {
    return {
      isShowModal: false,
      slideIndex: null,
      imageSlide: [],
      slideTitle: ''
    }
  },

  computed: {
    swiperOptions() {
			return {
        spaceBetween: 8,
        slidesPerView: this.slidesPerView,
				navigation: {
					nextEl: `#${this.navigationNext}`,
					prevEl: `#${this.navigationPrev}`
				},
			};
		},
    navigationNext() {
      return `property-data-carousel-next-${this.carouselId}`
    },
    navigationPrev() {
      return `property-data-carousel-prev-${this.carouselId}`
    },
    imageList() {
      const list = this.slides || {};
      Object.keys(list).forEach(key => {
        if (list[key].length === 0) {
          delete list[key];
        }
      });

      return list;
    }
  },

  filters: {
    photoTitle(value) {
      switch (value) {
        case 'buildings':
          return 'Foto Bangunan';
        case 'roadview':
          return 'Foto Tampak dari Jalan';
        case 'rooms':
          return 'Foto Kamar';
        case 'bathrooms':
          return 'Foto Kamar Mandi';
        case 'shared_facilities':
          return 'Foto Fasilitas Umum';
        case 'rules':
          return 'Foto Peraturan Kos';
        case 'others':
          return 'Foto Lainnya';
        default:
          return '';
      }
    }
  },

  props: {
    icon: {
      type: String,
      default: '',
    },
    carouselId: {
      type: String,
      default: ''
    },
    slidesPerView: {
      type: [String, Number],
      default: 1.5
    },
    slides: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    handleShowModal(image,title) {
      this.imageSlide = image;
      this.slideTitle = title;
      this.isShowModal = !this.isShowModal;
    }
  },
};
</script>

<style lang="scss" scoped src="./PropertyDataCarousel.scss"></style>
