<template>
  <div class="property-room-info-card">
    <bg-card variant="line">
      <div
        class="property-room-info-card__flex property-room-info-card__header"
      >
        <bg-text size="heading-4">Informasi Kamar</bg-text>
        <router-link
          v-if="accessEditListingInfo"
          :to="formPropertyListingInfoRoute"
        >
          <bg-button>Ubah</bg-button>
        </router-link>
      </div>
      <bg-divider class="property-room-info-card__divider" />
      <bg-grid>
        <bg-grid-item :col="3">
          <bg-text size="body-1">List ID</bg-text>
          <bg-text size="body-2">{{ listingId }}</bg-text>
        </bg-grid-item>
        <bg-grid-item :col="3">
          <bg-text size="body-1">Jenis Kamar</bg-text>
          <bg-text size="body-2">{{ listingGender }}</bg-text>
        </bg-grid-item>
        <bg-grid-item :col="3">
          <bg-text size="body-1">Total Kamar</bg-text>
          <bg-text size="body-2">{{ listingTotalUnit }} kamar</bg-text>
        </bg-grid-item>
        <bg-grid-item :col="3">
          <bg-text size="body-1">Kamar Kosong</bg-text>
          <bg-text size="body-2">{{ listingAvailableUnit }} kamar</bg-text>
        </bg-grid-item>
      </bg-grid>
      <div class="property-room-info-card__section">
        <bg-text size="body-1">Iklan di Mamikos</bg-text>
        <bg-link
          variant="medium"
          @click="handleRedirectToPageUrl(listingUrl)"
          >{{ listingUrl }}</bg-link
        >
      </div>
      <bg-divider class="property-room-info-card__divider" />
      <div class="property-room-info-card__section">
        <bg-text size="body-1">Nomor Kamar</bg-text>
        <div v-for="(roomInfo, i) in roomData" :key="i">
          <bg-accordion
            :id="`room-${i}`"
            class="property-room-info-card__section-room"
            size="sm"
            :opened="roomInfo.isOpen"
            @toggle-content="roomInfo.isOpen = !roomInfo.isOpen"
          >
            <template #title>
              <bg-text size="body-2">
                {{ roomInfo.name }} (Lantai {{ roomInfo.floor }})
              </bg-text>
              <bg-label-rainbow
                class="bg-u-mx-xs"
                :color="roomStatusInfo[roomInfo.status].color"
              >
                {{ roomStatusInfo[roomInfo.status].text }}
              </bg-label-rainbow>
              &#183;
              <bg-text size="body-2" italic class="bg-u-ml-xs">
                {{
                  roomInfo.window_location
                    ? `Letak Jendela: ${roomInfo.window_location}`
                    : 'Tidak ada jendela'
                }}
              </bg-text>
            </template>
            <bg-grid>
              <bg-grid-item v-if="roomInfo.room_size" :col="6">
                <bg-text size="body-1">Ukuran Kamar</bg-text>
                <bg-text size="body-2">
                  {{ roomInfo.room_size }} meter
                </bg-text>
              </bg-grid-item>
              <bg-grid-item v-if="roomInfo.electricity_type" :col="6">
                <bg-text size="body-1">Jenis Listrik</bg-text>
                <bg-text size="body-2">
                  {{ roomInfo.electricity_type }}
                </bg-text>
              </bg-grid-item>
              <bg-grid-item v-if="roomInfo.roomBedInfo" :col="6">
                <bg-text size="body-1">Tipe Kasur</bg-text>
                <bg-text size="body-2">
                  {{ roomInfo.roomBedInfo }}
                </bg-text>
              </bg-grid-item>
              <bg-grid-item v-if="roomInfo.electricity_power" :col="6">
                <bg-text size="body-1">Daya Listrik</bg-text>
                <bg-text size="body-2">
                  {{ roomInfo.electricity_power }} VA
                </bg-text>
              </bg-grid-item>
              <bg-grid-item v-if="roomInfo.description" :col="6">
                <bg-text size="body-1">Deskripsi kamar</bg-text>
                <bg-text size="body-2">
                  {{ roomInfo.description }}
                </bg-text>
              </bg-grid-item>
            </bg-grid>
          </bg-accordion>
        </div>
      </div>
      <bg-button
        v-if="hasMoreRoom"
        @click="isShowMoreRoom = !isShowMoreRoom"
      >
        {{ showMoreButtonText }}
      </bg-button>
    </bg-card>
  </div>
</template>

<script>
import {
  BgCard,
  BgText,
  BgDivider,
  BgGrid,
  BgGridItem,
  BgLink,
  BgButton,
  BgAccordion,
  BgLabelRainbow,
} from 'bangul-vue';

const ROOM_STATUS_INFO = {
  vacant: {
    color: 'yellow',
    text: 'Vacant',
  },
  out_of_order: {
    color: 'white',
    text: 'Out Of Order',
  },
  occupied: {
    color: 'green',
    text: 'Occupied',
  },
};

export default {
  name: 'PropertyRoomInfoCard',

  components: {
    BgCard,
    BgText,
    BgGrid,
    BgGridItem,
    BgDivider,
    BgLink,
    BgButton,
    BgAccordion,
    BgLabelRainbow,
  },

  props: {
    listingData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isShowMoreRoom: false,
      roomStatusInfo: ROOM_STATUS_INFO,
    };
  },

  computed: {
    accessEditListingInfo() {
      return this.$store.getters.xCheckUserPermission(
        'property-detail-edit-informasi-kamar'
      );
    },
    hasMoreRoom() {
      return this.listingData?.units.length > 3;
    },
    roomData() {
      return this.isShowMoreRoom
        ? this.listingData?.units
        : this.listingData?.units.slice(0, 3);
    },
    showMoreButtonText() {
      if (this.isShowMoreRoom) {
        return 'Tampilkan Lebih Sedikit';
      }
      return 'Lihat Semua Nomor Kamar Tipe Ini';
    },
    listingId() {
      return this.listingData?.id || '-';
    },
    listingGender() {
      return this.listingData?.gender || '-';
    },
    listingTotalUnit() {
      return this.listingData?.total_unit || '-';
    },
    listingAvailableUnit() {
      return this.listingData?.available_unit || '-';
    },
    listingUrl() {
      return this.listingData?.page_url;
    },
    formPropertyListingInfoRoute() {
      return {
        name: 'edit.property-info',
        params: {
          propertyId: this.$route.params.propertyId,
          listingId: this.listingId,
        },
      };
    },
  },

  methods: {
    handleRedirectToPageUrl(url) {
      window.open(url);
      window.opener = null;
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyRoomInfoCard.scss"></style>
