<template>
  <div class="property-photo-modal">
    <bg-modal
      :value="isShowPhoto"
      :title="filteredTitle"
      @close="handleClose"
    >
      <div class="property-photo-modal__swiper">
        <div
          class="property-photo-modal__swiper-nav property-photo-modal__swiper-nav--prev">
          <bg-button-icon
            icon="chevron-left"
            size="lg"
            class="property-photo-modal__swiper-nav-icon"
          />
        </div>
        <bg-swiper
          :slides="slideData"
          :swiper-options="photoSwiperOptions"
          :show-navigation="false"
          class="property-photo-modal__swiper-image"
          image-ratio="1:1"
        />
        <div
          class="property-photo-modal__swiper-nav property-photo-modal__swiper-nav--next">
          <bg-button-icon
            icon="chevron-right"
            size="lg"
            class="property-photo-modal__swiper-nav-icon"
          />
        </div>
      </div>
    </bg-modal>
  </div>
</template>

<script>
import { BgModal, BgSwiper, BgButtonIcon } from 'bangul-vue';

export default {
  name: 'PropertyPhotoModal',

  components: {
    BgModal,
    BgSwiper,
    BgButtonIcon
  },

  computed: {
    photoSwiperOptions() {
      return {
        initialSlide: this.index,
        navigation: {
          nextEl: '.property-photo-modal__swiper-nav--next',
          prevEl: '.property-photo-modal__swiper-nav--prev'
        },
      }
    },
    slideData() {
      const slideImages = this.slides.map((item,i) =>(
       {
         id: `$(this.title}-${i}`,
         imageUrl: item,
         imageAlt: this.filteredTitle,
       })
     );

     return slideImages;
    },
    
    filteredTitle() {
      return this.$options.filters.photoTitle(this.title);
    }
  },

  filters: {
    photoTitle(value) {
      switch (value) {
        case 'buildings':
          return 'Foto Bangunan';
        case 'roadview':
          return 'Foto Tampak dari Jalan';
        case 'rooms':
          return 'Foto Kamar';
        case 'bathrooms':
          return 'Foto Kamar Mandi';
        case 'shared_facilities':
          return 'Foto Fasilitas Umum';
        case 'rules':
          return 'Foto Peraturan Kos';
        case 'others':
          return 'Foto Lainnya';
        default:
          return '';
      }
    }
  },

  props: {
    isShowPhoto: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    slides: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    handleClose() {
      this.$emit('handle-close-modal');
    }
  },
};
</script>

<style lang="scss" scoped src="./PropertyPhotoModal.scss"></style>
