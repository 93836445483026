<template>
  <div class="property-room-pricelist">
    <bg-card variant="line">
      <div class="property-room-pricelist__flex property-room-pricelist__header">
        <bg-text size="heading-4">Harga Sewa Kamar</bg-text>
        <router-link
          v-if="accessEditPriceList"
          :to="formPropertyPriceList"
        >
          <bg-button>Ubah</bg-button>
        </router-link>
      </div>

      <bg-divider class="property-room-pricelist__divider" />
      <bg-grid vAlign="bottom">
        <bg-grid-item
          v-for="(price,i) in pricelistData"
          :key="i"
          :col="3"
          :class="price.normal_price ? '' : 'property-room-pricelist__hide'">
          <template v-if="price.normal_price">
            <div v-if="price.promo_price"
              class="property-room-pricelist__discount">
              <bg-text size="label-3" class="property-room-pricelist__discount-icon" >
                <bg-icon name="flash" size="sm" />
                {{ price.discount_percentage }}%
              </bg-text>
              <bg-text size="label-4" class="property-room-pricelist__discount-price">
                {{ price.normal_price | rupiah }}
              </bg-text>
            </div>
            <div class="property-room-pricelist__item">
              <bg-text size="body-1">{{ 
                price.promo_price ? price.promo_price : price.normal_price
                | rupiah }} </bg-text>
              <bg-text size="body-2"
                class="property-room-pricelist__discount-space">
                / {{ i | duration }}</bg-text>
            </div>
          </template>
        </bg-grid-item>
      </bg-grid>
    </bg-card>
  </div>
</template>

<script>
import { BgCard, BgText, BgDivider, BgGrid, BgGridItem, BgButton, BgIcon } from 'bangul-vue';
import { rupiahFormatter } from 'Utils/formatter';

export default {
  name: 'PropertyRoomPriceListCard',

  components: {
    BgCard,
    BgText,
    BgGrid,
    BgGridItem,
    BgDivider,
    BgButton,
    BgIcon
  },

  filters: {
    rupiah: rupiahFormatter,
    duration(value) {
      switch (value) {
        case 'daily':
          return 'Hari';
        case 'weekly':
          return 'Minggu';
        case 'monthly':
          return 'Bulan';
        case 'annually':
          return 'Tahun';
        case 'quarterly':
          return '3 Bulan';
        case 'semiannually':
          return '6 Bulan';
        default:
          return '';
      }
    },
  },
  
  props: {
    listingData: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    accessEditPriceList() {
      return this.$store.getters.xCheckUserPermission('property-detail-edit-harga-sewa');
    },
    pricelistData() {
      return this.listingData?.prices;
    },
    listingId() {
      return this.listingData?.id || '-';
    },
    formPropertyPriceList() {
      return {
        name: 'edit.property-price-list',
        params: {
          propertyId: this.$route.params.propertyId,
          listingId: this.listingId
        },
      };
    },

  }
};
</script>

<style lang="scss" scoped src="./PropertyRoomPriceListCard.scss"></style>
