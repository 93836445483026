<template>
  <bg-skeleton v-if="loading" width="100%" :height="800" />
  <div v-else class="property-detail-data">
    <property-room-info-card
    :listing-data="listingData"
    />
    <property-room-price-list-card
    :listing-data="listingData" />
    <property-room-add-ons-card :listing-data="listingData" />
    <property-room-facility-card
    :listing-data="listingData" />
  </div>
</template>

<script>
import { BgSkeleton } from 'bangul-vue';
import PropertyRoomInfoCard from '@admin/pages/PropertyDetailKos/components/PropertyDataRightSection/components/PropertyRoomInfoCard';
import PropertyRoomPriceListCard from '@admin/pages/PropertyDetailKos/components/PropertyDataRightSection/components/PropertyRoomPriceListCard';
import PropertyRoomAddOnsCard from '@admin/pages/PropertyDetailKos/components/PropertyDataRightSection/components/PropertyRoomAddOnsCard';
import PropertyRoomFacilityCard from '@admin/pages/PropertyDetailKos/components/PropertyDataRightSection/components/PropertyRoomFacilityCard';

export default {
  name: 'PropertyDetailData',

  components: {
    PropertyRoomInfoCard,
    PropertyRoomPriceListCard,
    PropertyRoomAddOnsCard,
    PropertyRoomFacilityCard,
    BgSkeleton
  },

  props: {
    listingData: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped src="./PropertyDetailData.scss"></style>
