<template>
  <div class="property-data-list-item">
    <img
      class="property-data-list-item__icon"
      v-if="icon"
      :src="icon"
      alt="list item icon"
    />
    <bg-icon
      v-else-if="defaultIcon"
      :name="defaultIcon"
      class="property-data-list-item__icon" />
    <span v-else class="property-data-list-item__bullet">&#8226; </span>
    <div class="property-data-list-item__content">
      <slot name="title" />
      <span class="property-data-list-item__content-bottom">
        <slot name="content" />
      </span>
    </div>
  </div>
</template>

<script>
import { BgIcon } from 'bangul-vue';

export default {
  name: 'PropertyDataListItem',

  components: {
    BgIcon
  },

  props: {
    icon: {
      type: String,
      default: ''
    },
    defaultIcon: {
      type: String,
      default: ''
    }
  },
};
</script>

<style lang="scss" scoped src="./PropertyDataListItem.scss"></style>
