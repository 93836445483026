<template>
  <div class="property-data-left" data-testid="propertyDataLeft">
    <bg-skeleton v-if="loading.detail" width="100%" :height="500" />
    <bg-card v-else>
      <div class="property-data-left__header">
        <bg-text size="heading-4">Data Properti</bg-text>
        <router-link
          v-if="accessEditPropertyInformation"
          :to="formPropertyInformationRoute"
        >
          <bg-button>Ubah</bg-button>
        </router-link>
      </div>
      <bg-divider class="property-data-left__divider" />
      <bg-text size="heading-5">Informasi Penjaga</bg-text>
      <div v-if="propertyKeepers">
        <div v-for="(keeper, i) in propertyKeepers" :key="i">
          <div class="property-data-left__info-row">
            <bg-text size="body-1">Nama Penjaga</bg-text>
            <bg-text size="body-2">{{ keeper.name || '-' }}</bg-text>
          </div>
          <div class="property-data-left__info-row">
            <bg-text size="body-1">No. HP Penjaga</bg-text>
            <bg-text size="body-2">{{ keeper.phone_number || '-' }}</bg-text>
          </div>
        </div>
      </div>

      <bg-divider class="property-data-left__divider" />
      <bg-text size="heading-5">Jadwal Survei yang Tersedia</bg-text>
      <div class="property-data-left__info-row">
        <bg-text size="body-2"
          >{{ surveyTimeFrom }} - {{ surveyTimeTo }}</bg-text
        >
      </div>

      <bg-divider class="property-data-left__divider" />
      <bg-accordion
        id="property-accordion-1"
        :opened="isAccordionActive('property-accordion-1')"
        @toggle-content="handleAccordionToggle('property-accordion-1')"
        size="md"
      >
        <template #title> Lokasi Sekitar Kos </template>
        <bg-skeleton v-if="loading.landmark" width="100%" :height="20" />
        <template v-else>
          <div v-for="(landmark, i) in propertyLandmark" :key="i">
            <property-data-list-item>
              <template #title>
                <bg-text size="title-4">{{ landmark.category_name }}</bg-text>
              </template>
              <template #content>
                <bg-text
                  size="body-2"
                  v-for="(place, i) in landmark.landmarks"
                  :key="i"
                  >{{ place.name }}
                  <span v-if="place.distance"
                    >({{ place.distance }} m)</span
                  ></bg-text
                >
              </template>
            </property-data-list-item>
          </div>
        </template>
      </bg-accordion>

      <bg-accordion
        id="property-accordion-2"
        :opened="isAccordionActive('property-accordion-2')"
        @toggle-content="handleAccordionToggle('property-accordion-2')"
        size="md"
      >
        <template #title> Lokasi Strategis </template>
        <div v-if="!detailData.strategic_location.length">-</div>
        <template v-else>
          <property-data-list-item
            v-for="(location, i) in detailData.strategic_location"
            :key="i"
          >
            <template #title>
              <bg-text size="body-2">{{ location }}</bg-text>
            </template>
          </property-data-list-item>
        </template>
      </bg-accordion>

      <bg-accordion
        id="property-accordion-3"
        :opened="isAccordionActive('property-accordion-3')"
        @toggle-content="handleAccordionToggle('property-accordion-3')"
        size="md"
      >
        <template #title> Fasilitas Bersama Kos </template>
        <div v-for="(facility, i) in detailData.shared_facilities" :key="i">
          <property-data-list-item :icon="facility.icon_url">
            <template #title>
              <bg-text size="body-2">{{ facility.name }}</bg-text>
            </template>
            <template #content>
              <bg-text size="body-2" class="property-data-left__grey-text">
                <span v-if="facility.name === 'WiFi'">
                  <span v-if="detailData.wifi_speed"
                    >Kecepatan rata-rata {{ detailData.wifi_speed }}&nbsp;</span
                  >
                  <span v-if="detailData.wifi_provider">{{
                    detailData.wifi_provider
                  }}</span>
                </span>
                <span v-else>{{ facility.description }}</span>
              </bg-text>
            </template>
          </property-data-list-item>
        </div>
      </bg-accordion>

      <bg-accordion
        id="property-accordion-4"
        :opened="isAccordionActive('property-accordion-4')"
        @toggle-content="handleAccordionToggle('property-accordion-4')"
        size="md"
      >
        <template #title> Fasilitas Parkir </template>
        <div v-for="(parking, i) in detailData.parking_facilities" :key="i">
          <property-data-list-item :icon="parking.icon_url">
            <template #title>
              <div class="property-data-left__flex-row">
                <bg-text size="body-1"
                  >{{ parking.parking_type }}
                  <span class="property-data-left__dot">&#8226;</span>
                </bg-text>
                <bg-text size="body-2" italic>
                  {{ parking.total_quota }} slot</bg-text
                >
              </div>
            </template>
            <template #content>
              <bg-text size="body-2" class="property-data-left__grey-text">{{
                parking.description
              }}</bg-text>
            </template>
          </property-data-list-item>
        </div>
      </bg-accordion>

      <bg-accordion
        id="property-accordion-5"
        :opened="isAccordionActive('property-accordion-5')"
        @toggle-content="handleAccordionToggle('property-accordion-5')"
        size="md"
      >
        <template #title> Peraturan Kos </template>
        <div v-for="(rules, i) in detailData.property_rules" :key="i">
          <property-data-list-item>
            <template #title>
              <bg-text size="body-2">{{ rules.name }}</bg-text>
            </template>
          </property-data-list-item>
        </div>
      </bg-accordion>
      <bg-accordion
        id="property-accordion-6"
        :opened="isAccordionActive('property-accordion-6')"
        @toggle-content="handleAccordionToggle('property-accordion-6')"
        size="md"
      >
        <template #title> Info Lainnya </template>
        <property-data-list-item
          v-if="detailData.portal_open_time && detailData.portal_close_time"
        >
          <template #title>
            <bg-text size="body-2">Portal di Lingkungan Kos</bg-text>
          </template>
          <template #content>
            Buka: {{ detailData.portal_open_time }} -
            {{ detailData.portal_close_time }}
          </template>
        </property-data-list-item>
        <property-data-list-item v-if="detailData.mainroad_distance">
          <template #title>
            <bg-text size="body-2">Akses ke jalan utama</bg-text>
          </template>
          <template #content>
            {{ detailData.mainroad_distance }} meter
          </template>
        </property-data-list-item>
        <property-data-list-item
          v-for="(info, i) in detailData.additional_infos"
          :key="i"
        >
          <template #title>
            <bg-text size="body-2">{{ info.name }}</bg-text>
          </template>
          <template #content>
            {{ info.description }}
          </template>
        </property-data-list-item>
      </bg-accordion>
    </bg-card>
  </div>
</template>

<script>
import {
  BgCard,
  BgText,
  BgDivider,
  BgAccordion,
  BgSkeleton,
  BgButton,
} from 'bangul-vue';
import propertyDetailApi from '@admin_endpoints/property-detail';
import PropertyDataListItem from '@admin/pages/PropertyDetailKos/components/PropertyDataListItem';

export default {
  name: 'PropertyDataLeftSection',

  components: {
    BgCard,
    BgText,
    BgDivider,
    BgSkeleton,
    BgAccordion,
    BgButton,
    PropertyDataListItem,
  },

  computed: {
    accessEditPropertyInformation() {
      return this.$store.getters.xCheckUserPermission(
        'property-detail-edit-data-properti'
      );
    },
    propertyId() {
      return this.$route.params.propertyId;
    },
    propertyKeepers() {
      return this.detailData?.property_keepers;
    },
    surveyTimeFrom() {
      return this.detailData.survey_time?.survey_time_from || '';
    },
    surveyTimeTo() {
      return this.detailData.survey_time?.survey_time_to || '';
    },
    formPropertyInformationRoute() {
      return {
        name: 'edit.property-information',
        params: {
          propertyId: this.$route.params.propertyId,
        },
      };
    },
  },

  data() {
    return {
      activeAccordion: [],
      loading: {
        landmark: false,
        detail: false,
      },
      propertyLandmark: [],
      detailData: {},
    };
  },

  created() {
    this.consumePropertyDetail();
    this.consumePropertyLandmark();
  },

  methods: {
    async fetchPropertyLandmark() {
      let result = {};
      try {
        result = await propertyDetailApi.getPropertyLandmark(this.propertyId);
      } catch (error) {
        result.hasError = true;
        this.$toast.show(result);
        console.error(error);
      }
      return result;
    },

    async consumePropertyLandmark() {
      this.loading.landmark = true;
      const response = await this.fetchPropertyLandmark();

      if (!response.hasError) {
        this.propertyLandmark = response.data.nearby_landmarks;
      }

      this.loading.landmark = false;
    },

    async fetchPropertyDetail() {
      let result = {};
      try {
        result = await propertyDetailApi.getKosRulesAndInfos(this.propertyId);
      } catch (error) {
        result.hasError = true;
        this.$toast.show(result);
        console.error(error);
      }
      return result;
    },

    async consumePropertyDetail() {
      this.loading.detail = true;
      const response = await this.fetchPropertyDetail();

      if (!response.hasError) {
        const { data } = response.data;
        this.detailData = data;
      }

      this.loading.detail = false;
    },

    handleAccordionToggle(id) {
      if (this.isAccordionActive(id)) {
        this.activeAccordion.splice(this.getAccordionIndex(id), 1);
      } else {
        this.activeAccordion.push(id);
      }
    },

    isAccordionActive(id) {
      return this.getAccordionIndex(id) > -1;
    },

    getAccordionIndex(id) {
      return this.activeAccordion.indexOf(id);
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyDataLeftSection.scss"></style>
