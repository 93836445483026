<template>
  <div class="property-data-slide-item">
    <img class="property-data-slide-item__picture" :src="image" :alt="title | photoTitle" @click="handleClickPhoto" />
    <div class="property-data-slide-item__caption">
      <bg-text size="title-5">{{ title | photoTitle}}</bg-text>
      <bg-button @click="handleClickPhoto">Lihat Semua</bg-button>
    </div>
  </div>
</template>

<script>
import { BgText, BgButton } from 'bangul-vue';

export default {
  name: 'PropertyDataSlideItem',

  components: {
    BgText,
    BgButton
  },

  props: {
    image: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },

  filters: {
    photoTitle(value) {
      switch (value) {
        case 'buildings':
          return 'Foto Bangunan';
        case 'roadview':
          return 'Foto Tampak dari Jalan';
        case 'rooms':
          return 'Foto Kamar';
        case 'bathrooms':
          return 'Foto Kamar Mandi';
        case 'shared_facilities':
          return 'Foto Fasilitas Umum';
        case 'rules':
          return 'Foto Peraturan Kos';
        case 'others':
          return 'Foto Lainnya';
        default:
          return '';
      }
    }
  },

  methods: {
    handleClickPhoto() {
      this.$emit('handle-click-photo');
    }
  }
};
</script>

<style lang="scss" scoped src="./PropertyDataSlideItem.scss"></style>
