<template>
  <div
    class="property-data-right-section"
    data-testid="propertyDataRightSection"
  >
    <bg-skeleton v-if="isLoadingDetails" width="100%" :height="800" />
    <div v-else class="property-data-right-section__tab">
      <div class="property-data-right-section__tab--list">
        <div v-for="(type, i) in kosListing" :key="i">
          <bg-button
            v-if="type.unit_type && kosListing.length > 1"
            :id="`${type.id}`"
            class="property-data-right-section__tab--item"
            :class="
              index === type.id
                ? 'property-data-right-section__tab--item-active'
                : 'property-data-right-section__tab--item-inactive'
            "
            variant="tertiary"
            rounded
            @click="handleIndex(type.id, type.unit_type)"
          >
            {{ type.unit_type }}
          </bg-button>
        </div>
      </div>
      <property-detail-data
        :loading="isLoadingListing"
        :listing-data="listingData"
      />
    </div>
  </div>
</template>

<script>
import { BgButton, BgSkeleton } from 'bangul-vue';
import { mapState, mapActions } from 'vuex';
import PropertyDetailData from '@admin/pages/PropertyDetailKos/components/PropertyDataRightSection/components/PropertyDetailData';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';

export default {
  name: 'PropertyDataRightSection',

  components: {
    PropertyDetailData,
    BgButton,
    BgSkeleton,
  },

  computed: {
    ...mapState('propertyDetail', [
      'xLoadingState',
      'xLoadingStateListing',
      'xPropertyDetail',
      'xKosListingData',
    ]),

    propertyId() {
      return this.$route.params.propertyId;
    },

    isLoadingDetails() {
      return this.xLoadingState === LOADING_STATE.FETCHING;
    },

    isLoadingListing() {
      return this.xLoadingStateListing === LOADING_STATE.FETCHING;
    },

    listingData() {
      return this.kosListingData.find(listing => listing.id === this.index);
    },
  },

  data() {
    return {
      roomType: [],
      kosListing: [],
      kosListingData: [],
      kosId: '',
      index: 0,
    };
  },

  props: {
    loadingListing: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    this.kosListingData = [];
  },

  watch: {
    xLoadingState: {
      immediate: true,
      handler(value) {
        if (value === 'fetch_done') {
          this.consumePropertyDetail(this.xPropertyDetail);
        }
      },
    },
    xKosListingData: {
      immediate: true,
      handler(value) {
        this.kosListingData.push(value);
      },
    },
  },

  methods: {
    ...mapActions('propertyDetail', ['xConsumeKosListing']),

    consumePropertyDetail(property) {
      this.kosListing = property?.listings || [];

      this.index = this.kosListing[0]?.id;
      this.handleIndex(this.index, this.kosListing[0]?.unit_type);
      localStorage.setItem('propertyName', property?.name);

      this.kosListing.forEach(list => {
        this.xConsumeKosListing({
          propertyId: this.propertyId,
          listingId: list.id,
        });
      });
    },

    handleIndex(id, roomType) {
      this.index = id;

      localStorage.setItem('selectedRoomTypeToUpdate', roomType || '');
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyDataRightSection.scss"></style>
