<template>
  <div class="property-detail-kos" data-testid="propertyDetailKos">
    <bg-grid>
        <bg-grid-item :col="4">
          <property-data-left-section />
        </bg-grid-item>
        <bg-grid-item :col="8">
          <property-data-right-section />
        </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import { BgGrid, BgGridItem } from 'bangul-vue';
import PropertyDataLeftSection from '@admin/pages/PropertyDetailKos/components/PropertyDataLeftSection';
import PropertyDataRightSection from '@admin/pages/PropertyDetailKos/components/PropertyDataRightSection';

export default {
  name: 'PropertyDetailKos',

  components: {
    PropertyDataLeftSection,
    BgGrid,
    BgGridItem,
    PropertyDataRightSection
  },

};
</script>

<style lang="scss" scoped src="./PropertyDetailKos.scss"></style>
