import { render, staticRenderFns } from "./PropertyDetailData.vue?vue&type=template&id=5d6d77a2&scoped=true&"
import script from "./PropertyDetailData.vue?vue&type=script&lang=js&"
export * from "./PropertyDetailData.vue?vue&type=script&lang=js&"
import style0 from "./PropertyDetailData.scss?vue&type=style&index=0&id=5d6d77a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d6d77a2",
  null
  
)

export default component.exports