<template>
  <div class="property-room-facility-card">
    <bg-card variant="line">
      <div class="property-room-facility-card__header-section">
        <bg-text size="heading-5">Fasilitas</bg-text>
        <router-link
          v-if="accessEditRoomFacility"
          :to="formPropertyFacilityRoute"
        >
          <bg-button>Ubah</bg-button>
        </router-link>
      </div>
      <bg-divider class="property-room-facility-card__divider" />
      <div class="property-room-facility-card__section">
        <bg-text
          size="heading-5"
          class="property-room-facility-card__subtitle">Fasilitas Kamar</bg-text>
        <bg-grid>
          <bg-grid-item
            v-for="(facility,i) in shownRoomFacilityData"
            :key="i"
            :col="3"
            class="property-room-facility-card__item">
            <property-data-list-item :icon="facility.icon_url">
              <template #title>
                <bg-text size="body-2">{{ facility.name }}</bg-text>
              </template>
            </property-data-list-item>
          </bg-grid-item>
        </bg-grid>
        <div v-if="isShowMoreRoom">
          <bg-grid>
            <bg-grid-item
              v-for="(facility,i) in remainingRoomFacilityData"
              :key="i"
              :col="3"
              class="property-room-facility-card__item">
              <property-data-list-item :icon="facility.icon_url">
                <template #title>
                  <bg-text size="body-2">{{ facility.name }}</bg-text>
                </template>
              </property-data-list-item>
            </bg-grid-item>
          </bg-grid>
        </div>
        <bg-button v-if="hasMoreRoomFacility" @click="handleShowMoreRoomFacility">{{ showMoreRoomFacilityText }}</bg-button>
      </div>

      <bg-divider class="property-room-facility-card__divider" />

      <div class="property-room-info-card__section">
        <bg-text
        size="heading-5"
        class="property-room-facility-card__subtitle">Fasilitas Kamar Mandi</bg-text>
        <bg-grid>
          <bg-grid-item
            v-for="(bathroom,i) in shownBathroomFacilityData"
            :key="i"
            :col="3"
            class="property-room-facility-card__item">
            <property-data-list-item :icon="bathroom.icon_url">
              <template #title>
                <bg-text size="body-2">{{ bathroom.name }}</bg-text>
              </template>
            </property-data-list-item>
          </bg-grid-item>
        </bg-grid>
        <div v-if="isShowMoreBathroom">
          <bg-grid>
            <bg-grid-item
              v-for="(facility,i) in remainingRoomFacilityData"
              :key="i"
              :col="3"
              class="property-room-facility-card__item">
              <property-data-list-item :icon="facility.icon_url">
                <template #title>
                  <bg-text size="body-2">{{ facility.name }}</bg-text>
                </template>
              </property-data-list-item>
            </bg-grid-item>
          </bg-grid>
        </div>
        <bg-button v-if="hasMoreBathroomFacility" @click="handleShowMoreBathroomFacility">{{ showMoreBathroomFacilityText }}</bg-button>
        <div class="property-room-info-card__section">
          <property-data-carousel
            carousel-id="facility-carousel"
            slides-per-view="2.5"
            :slides="photoData"
          />
        </div>
      </div>
    </bg-card>
  </div>
</template>

<script>
import { BgCard, BgText, BgDivider, BgGrid, BgGridItem, BgButton } from 'bangul-vue';
import PropertyDataListItem from '@admin/pages/PropertyDetailKos/components/PropertyDataListItem';
import PropertyDataCarousel from '@admin/pages/PropertyDetailKos/components/PropertyDataCarousel';

export default {
  name: 'PropertyRoomFacilityCard',

  components: {
    BgCard,
    BgText,
    BgDivider,
    BgGrid,
    BgGridItem,
    BgButton,
    PropertyDataListItem,
    PropertyDataCarousel
  },

  props: {
    listingData: {
      type: Object,
      default: () => {}
    },
    facilityData: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      isShowMoreRoom: false,
      isShowMoreBathroom: false
    }
  },

  computed: {
    accessEditRoomFacility() {
      return this.$store.getters.xCheckUserPermission('property-detail-edit-fasilitas');
    },
    listingId() {
      return this.listingData?.id || 0;
    },
    roomData() {
      return this.listingData?.room_facilities;
    },
    bathroomData() {
      return this.listingData?.bathroom_facilities;
    },
    photoData() {
      return this.listingData?.photos;
    },
    hasMoreRoomFacility() {
      return this.listingData?.room_facilities.length > 12;
    },
    shownRoomFacilityData() {
      return this.listingData?.room_facilities.slice(0,12);
    },
    remainingRoomFacilityData() {
      return this.listingData?.room_facilities.slice(12);
    },
    showMoreRoomFacilityText() {
      if (this.isShowMoreRoom) {
        return 'Tampilkan Lebih Sedikit'
      } return 'Lihat Semua Fasilitas Kamar Tipe Ini';
    },
    hasMoreBathroomFacility() {
      return this.listingData?.bathroom_facilities.length > 12;
    },
    shownBathroomFacilityData() {
      return this.listingData?.bathroom_facilities.slice(0,12);
    },
    remainingBathroomFacilityData() {
      return this.listingData?.bathroom_facilities.slice(12);
    },
    showMoreBathroomFacilityText() {
      if (this.isShowMoreBathroom) {
        return 'Tampilkan Lebih Sedikit'
      } return 'Lihat Semua Fasilitas Kamar Mandi Tipe Ini';
    },
    formPropertyFacilityRoute() {
      return {
        name: 'edit.property-facility',
        params: {
          propertyId: this.$route.params.propertyId,
          listingId: this.listingId
        },
      };
    },
  },

  methods: {
    handleShowMoreRoomFacility() {
      this.isShowMoreRoom = !this.isShowMoreRoom;
    },
    handleShowMoreBathroomFacility() {
      this.isShowMoreBathroom = !this.isShowMoreBathroom;
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyRoomFacilityCard.scss"></style>
